import { RATING_COLORS, SENTIMENT_COLORS, TIME_GROUPING_COLORS } from '@/utils/constants'

export function generateTimeBuckets({ minDate, maxDate, moment }) {
  // in case date diff
  const daysDiff = moment(maxDate).diff(minDate, 'days')
  /*
  * if (daysDiff < 5)
  * TODO:
  *
  * */
  const daysLapCeil = Math.ceil(daysDiff / 5)
  const daysLapFloor = Math.floor(daysDiff / 5)
  const bucketArray = []

  let dayLap = daysLapCeil
  let startDateMomented = moment(minDate)
  const endDateMomented = moment(maxDate)
  if (startDateMomented.clone().add(dayLap * 4, 'days').isSameOrAfter(endDateMomented)) {
    dayLap = daysLapFloor
  }
  while (startDateMomented < endDateMomented) {
    // if we already have 4 items in the bucket
    // but we don't have end date then adding it
    // we should always  have start date and end date
    // in bucket
    if (bucketArray.length === 4 && !bucketArray.find((date) => date.isSame(endDateMomented))) {
      startDateMomented = endDateMomented
    }
    const dd = startDateMomented.clone()

    bucketArray.push(dd)
    startDateMomented = startDateMomented.add(dayLap, 'days')
  }
  if (bucketArray.length < 6) {
    bucketArray.push(endDateMomented)
  }

  const [
    firstIntervalStart,
    firstIntervalEnd,
    secondIntervalEnd,
    thirdIntervalEnd,
    fourthIntervalEnd,
    fifthIntervalEnd,
  ] = bucketArray
  const timeBucketIntervals = [
    `${firstIntervalStart.format('YYYY-MM-DD')}-${firstIntervalEnd.format('YYYY-MM-DD')}`,
    `${firstIntervalEnd.format('YYYY-MM-DD')}-${secondIntervalEnd.format('YYYY-MM-DD')}`,
    `${secondIntervalEnd.format('YYYY-MM-DD')}-${thirdIntervalEnd.format('YYYY-MM-DD')}`,
    `${thirdIntervalEnd.format('YYYY-MM-DD')}-${fourthIntervalEnd.format('YYYY-MM-DD')}`,
    `${fourthIntervalEnd.format('YYYY-MM-DD')}-${fifthIntervalEnd.format('YYYY-MM-DD')}`,
  ]

  return { timeBuckets: bucketArray, timeBucketIntervals }
}

export function generateColorMap({ colorBy, timeBucketIntervals }) {
  const COLOR_MODES = {
    SENTIMENT: 'netSentiment',
    RATING: 'rating',
    TIME: 'time',
  }

  const colorMap = {
    [COLOR_MODES.SENTIMENT]: { translate: true, colorMap: SENTIMENT_COLORS },
    [COLOR_MODES.RATING]: {
      translate: true,
      colorMap: RATING_COLORS
        .reduce((acc, color, index) => ({ ...acc, [index + 1]: color }), {}),
    },
    [COLOR_MODES.TIME]: {
      translate: false,
      colorMap: timeBucketIntervals
        .reduce((acc, interval, idx) => ({ ...acc, [interval]: TIME_GROUPING_COLORS[idx] }), {}),
    },
  }

  return colorMap[colorBy] || {}
}

export const TRACKING_EVENT_NAME_MAPPER = {
  'single-product': 'Single Product Deepdive',
  'single-category': 'Single Category Deepdive',
  'single-tag': 'Single Tag Deepdive',
  'single-brand': 'Single Brand Deepdive',
  'multi-product': 'Multi Product Comparison',
}
