<template>
  <v-card
    class="rdtypecard"
    :ripple="false"
    v-on="$listeners"
  >
    <v-img
      :src="getImage"
      height="148"
      width="244"
    />
    <v-card-title class="rdtypecard--label py-1 px-4">
      <p class="text-truncate d-inline-block">
        {{ getLabel }}
      </p>
    </v-card-title>
    <div
      v-if="disabled"
      class="rdtypecard--disabled"
    />
  </v-card>
</template>

<script>
import { TYPES_OF_DASHBOARD } from '@/utils/constants'

export default {
  name: 'RDashboardTypeCard',
  components: {
  },
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => Object.values(TYPES_OF_DASHBOARD).includes(value),
    },
    label: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    getImage() {
      let imgSrc = ''
      try {
        // eslint-disable-next-line import/no-dynamic-require, global-require
        imgSrc = require(`@/assets/images/dashboard/${this.type}.png`)
      } catch (error) {
        console.error(`Image '@/assets/images/dashboard/${this.type}.png' not found!`)
      }
      return imgSrc
    },
    getLabel() {
      return this.label ? this.label : this.$t(`dashboards.createDashboard.labelsForDashboardTypes.${this.type}`)
    },
  },

}
</script>

<style scoped>
.rdtypecard {
  width: 244px;
  position: relative;
}
.rdtypecard--disabled {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
}
.v-sheet.v-card {
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
}
.rdtypecard--label {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  height: 28px;
  border: 0.5px solid var(--r-border-color);
  background-color: white;
  max-width: 100%;
}
 .v-card--link:focus::before {
  opacity: 0;
}
</style>
